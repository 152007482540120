import React, {useEffect} from 'react';
import './App.css';

import LoginDialog from "./LoginDialog";
import RESTClient from "./RESTClient";
import AppAuthorized from "./AppAuthorized";

function App() {
    const [loginState, setLoginState] = React.useState({loading: true, registered: false, fbLogin: false});
    //const [loginState, setLoginState] = React.useState({loading: false, registered: true, fbLogin: true});
    const [userId, setUserId] = React.useState(-1);
    const [userToken, setUserToken] = React.useState();

    useEffect(() => {
        window.FB.getLoginStatus(response => {
           // console.log("FB response: ", response);

            if (response.status === "connected") {
                /*console.log("connected: ", response.authResponse.userID);*/
                setUserId(response.authResponse.userID);
                setUserToken(response.authResponse.accessToken);
                setLoginState({...loginState, fbLogin: true});
            } else {
                setLoginState({...loginState, loading: false});
            }
        });
    }, []);

    useEffect(() => {
        if (loginState.fbLogin) {
            RESTClient.get(`/api/user/registration/${userId}`, response => {
                setLoginState({...loginState, loading: false, registered: response.registered});
            })
        }
    }, [loginState.fbLogin])

    const loginSuccess = (userInfo) => {
        setUserId(userInfo.userID);
        setUserToken(userInfo.accessToken);
        setLoginState({...loginState, fbLogin: true});
    }

    if (loginState.fbLogin && !loginState.loading && !loginState.registered) {
        if (window.location.pathname.endsWith("/register")) {
            let params = new URLSearchParams(document.location.search);
            let registrationHash = params.get("registrationHash");
            RESTClient.put("/api/user/registration", {userId, registrationHash},
                response => {
                    setLoginState({...loginState, registered: true});
                });
        } else {
            console.log("Not on register page.");
        }
    }

    if (loginState.fbLogin && loginState.registered) {
        return <AppAuthorized userId={userId} userToken={userToken}/>;
    }

    return <LoginDialog loading={loginState.loading} fbLogin={loginState.fbLogin} loginSuccess={loginSuccess}/>;
}

export default App;
