import React from 'react';
import {useHistory} from "react-router";

import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from "@material-ui/core/TableFooter";
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Button from "@material-ui/core/Button";

import Csfd from './Csfd.js';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100%"
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    list: {
        width: 250,
    },
    container: {
        maxHeight: "50%"
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    }
}));

const columns = [
    { id: 'poster', label: 'Plakát', minWidth: 50 },
    { id: 'name', label: 'Název', minWidth: 170 },
    { id: 'genres', label: 'Žánr', minWidth: 100 },
    { id: 'seen', label: 'Viděli', minWidth: 50 },
    { id: 'want', label: 'Chtějí vidět', minWidth: 50 },
    { id: 'duration', label: 'Délka', minWidth: 50 },
    { id: 'addedBy', label: 'Přidal', minWidth: 120 },
    { id: 'addedTimestamp', label: 'Datum přidání', minWidth: 100}
];

const avatars = (
    <AvatarGroup>
        <Avatar alt="Dave" src="https://graph.facebook.com/v5.0/2741815595842099/picture" />
        <Avatar alt="Dave" src="https://graph.facebook.com/v5.0/2741815595842099/picture" />
        <Avatar alt="Dave" src="https://graph.facebook.com/v5.0/2741815595842099/picture" />
        <Avatar>+2</Avatar>
    </AvatarGroup>
);

const movies = [
    {poster: <Csfd.Poster posterId="000/039/39363_a977b4"/>, addedBy: "Petr", name: <Csfd.Link id="154261-eurotrip" name="Eurotrip" />, genres: "Komedie", seen: "2", want: "2", duration: 120, addedTimestamp: "11:37:52 1.1.2020"},
    {poster: <Csfd.Poster posterId="163/765/163765982_7d0a9e" />, addedBy: "Jana", name: "Joker", genres: "Akční", seen: "2", want: "1", duration: 110, addedTimestamp: "11:37:52 1.1.2020"},
    {poster: <Csfd.Poster posterId="159/630/159630132_262154" />, addedBy: "Martin", name: "Titanic", genres: "Drama", seen: avatars, want: "3", duration: 160, addedTimestamp: "11:37:52 1.1.2020"}
];

function MovieTable() {
    const classes = useStyles();
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {/*<TableSortLabel
                                        active={false}
                                        direction={'asc'}
                                        onClick={() => {}}
                                    >*/}
                                    {column.label}
                                    {/*</TableSortLabel>*/}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {movies.map(row => {
                            return (
                                <TableRow tabIndex={-1} key={row.name}>
                                    {columns.map(column => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={8} align="center"><Button fullWidth onClick={() => history.push("addMovie")}>+ přidat film +</Button></TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                colSpan={7}
                component="div"
                count={movies.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Řádků na stránku"
                labelDisplayedRows={({from, to, count}) => `${from}-${to} z ${count}`}
                // onChangePage={handleChangePage}
                //onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default MovieTable;