import { useHistory } from "react-router";

export function useRouter() {
    const history = useHistory();

    const router = {};

    router.goTo = (route) => {
        history.push("/" + route);
    };

    return router;
}