import React from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Drawer from "@material-ui/core/Drawer";
import {Redirect, Route, Switch, useHistory, useLocation} from "react-router";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import routes from "./routes";
import {makeStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles(theme => ({
    root: {
        height: "100%",
        width: "100%"
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    list: {
        width: 250,
    }
}));

function getRoute(routeId) {
    const foundRoute = routes.filter(route => route.id === routeId);

    return foundRoute.length >= 1 ? foundRoute[0] : routes[0];
}

function getHeadLine(location) {
    let routeId = location.pathname.slice(1, location.pathname.length);

    return getRoute(routeId).headLine || getRoute(routeId).text;
}

function AppAuthorized(props) {
    const classes = useStyles();
    const history = useHistory();
    let location = useLocation();
    const [isOpenMenu, setOpenMenu] = React.useState(false);

    const handleNavigation = (e) => {
        let elementId = e.currentTarget.id;

        history.push("/" + elementId);
    };

    const sideList = side => (
        <div
            className={classes.list}
            role="presentation"
        >
            <List>
                {routes
                    .filter(route => route.menuItem)
                    .map(route => {
                        return (
                            <ListItem button key={route.id} id={route.id} onClick={handleNavigation}>
                                <ListItemText primary={route.text}/>
                            </ListItem>
                        );
                    })}
            </List>
        </div>
    );

    const getRouteComponent = route => {
        // route.component.props = loginState;
        return route.component;
    }

    return <React.Fragment>
        <AppBar position="sticky">
            <Toolbar>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
                            onClick={() => {
                                setOpenMenu(true)
                            }}>
                    <MenuIcon/>
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    {getHeadLine(location)}
                </Typography>
                {/*<IconButton aria-label="notifications" color="inherit">
                    <Badge badgeContent={17} color="secondary">
                        <NotificationsIcon onClick={() => {
                            window.FB.getLoginStatus(response => {
                                console.log(response);
                            });
                        }}/>
                    </Badge>
                </IconButton>*/}
                <Avatar alt="Dave" src={`https://graph.facebook.com/v10.0/${props.userId}/picture?access_token=${props.userToken}`} />
            </Toolbar>
        </AppBar>
        <Drawer open={isOpenMenu} onClick={() => {
            setOpenMenu(false)
        }}>
            {sideList()}
        </Drawer>
        <div className={classes.root}>
            <Switch>
                {
                    routes.map(route => {
                        return (
                            <Route path={"/" + route.id} key={route.id}>
                                {getRouteComponent(route)}
                            </Route>
                        );
                    })
                }
                <Redirect from={"/"} to={"/home"}/>
            </Switch>
        </div>
    </React.Fragment>;
}

export default AppAuthorized;