import React, {useEffect, useState, useRef} from 'react';
import {useHistory} from "react-router";
import Link from '@material-ui/core/Link';

const defaultProps = {
    lat: 49.85,
    lng: 15.52,
    zoom: 8,
    height: "100%",
    width: "100%"
};

function Map(props) {
    const mapNode = useRef(null);
    const mapProps = {...defaultProps, ...props};
    const history = useHistory();

    useEffect(() => {
        const createMap = () => {
            const stred = window.SMap.Coords.fromWGS84(mapProps.lng, mapProps.lat);
            const map = new window.SMap(mapNode.current, stred, mapProps.zoom);
            map.addDefaultLayer(window.SMap.DEF_TURIST).enable();
            map.addDefaultControls();
            const sync = new window.SMap.Control.Sync();
            map.addControl(sync);

            /*map.getSignals().addListener(window, "map-click", (e, elm) => {
                const coords = window.SMap.Coords.fromEvent(e.data.event, map);
                console.log(coords);
                //alert("Kliknuto na " + coords.toWGS84(2).reverse().join(" "));
            });*/

            const layer = new window.SMap.Layer.Marker();
            const clusterer = new window.SMap.Marker.Clusterer(map);
            layer.setClusterer(clusterer);
            let markers = [];

            const he = () => {
                history.push("/activityDetail/1");
            }

            if(mapProps.activityList) {
                markers = mapProps.activityList.map(a => {
                    const card = new window.SMap.Card();
                    card.getHeader().innerHTML = "<h3>"+a.name+"</h3>";
                    card.getBody().innerHTML = a.description;
                    card.getFooter().innerHTML = '<a onClick={he}>test</a>';

                    const options = {
                        title: a.name
                    };
                    const coords = window.SMap.Coords.fromWGS84(a.lng, a.lat);
                    const marker = new window.SMap.Marker(coords, a.id, options);
                    marker.decorate(window.SMap.Marker.Feature.Card, <div>Hi div</div>);

                    return marker;
                });
            } else {
                const coords = window.SMap.Coords.fromWGS84(mapProps.lng, mapProps.lat);
                const marker = new window.SMap.Marker(coords);
                markers.push(marker);
            }

            layer.addMarker(markers);
            map.addLayer(layer).enable();

            document.body.removeChild(scriptTag);
        }

        const scriptTag = document.createElement("script");
        scriptTag.src = "https://api.mapy.cz/loader.js";
        scriptTag.onload = () => {
            window.Loader.async = true;
            window.Loader.load(null, null, createMap);
        };
        document.body.appendChild(scriptTag);
    }, [])

    return <div style={{height: mapProps.height, width: mapProps.width}} id="map_div" ref={mapNode}>
    </div>;
}

/*Map.defaultProps = {
    lat: 50.08,
    lng: 14.41,
    height: "100%",
    width: "100%"
};*/

export default Map;