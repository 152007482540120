import Home from "./Home";
import MovieTable from "./MovieTable";
import About from "./About";
import AddMovie from "./AddMovie";
import ActivityList from "./ActivityList";
import ActivityDetail from "./ActivityDetail";
import AddActivity from "./AddActivity";
import BigMap from "./BigMap";

const routes = [
    {
        id: "home",
        text: "Home",
        headLine: "Plánovač",
        component: <Home/>,
        menuItem: true
    },
    {
        id: "profile",
        text: "Můj profil",
        component: <div>"//TODO muj profil"</div>,
        menuItem: true
    },
/*    {
        id: "movies",
        text: "Seznam filmů",
        component: <MovieTable/>,
        menuItem: true
    },*/
    {
        id: "addMovie",
        text: "Přidat film",
        component: <AddMovie/>
    },
    {
        id: "activityList",
        text: "Aktivity",
        component: <ActivityList/>,
        menuItem: true
    },
    {
        id: "activityDetail",
        text: "Detail aktivity",
        component: <ActivityDetail/>
    },
    {
        id: "addActivity",
        text: "Pridat aktivitu",
        component: <AddActivity />
    },
    {
        id: "editActivity",
        text: "Editace aktivity",
        component: <AddActivity />
    },
    {
        id: "bigMap",
        text: "Mapa",
        component: <BigMap />,
        menuItem: true
    },
    {
        id: "actions",
        text: "Akce",
        component: "// TODO akce",
        menuItem: true
    },
/*    {
        id: "chronicle",
        text: "Kronika",
        component: "// TODO kronika",
        menuItem: true
    },*/
    {
        id: "payments",
        text: "Platby",
        component: "// TODO platby",
        menuItem: true
    },
    {
        id: "about",
        text: "O aplikaci",
        component: <About/>,
        menuItem: true
    }
];

export default routes;