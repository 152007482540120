import React from 'react';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

function ConfirmDialog(props) {
    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={props.open}
        >
            <DialogContent dividers>
                Opravdu smazat?
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.handleCancel} color="primary">
                    Ne
                </Button>
                <Button onClick={props.handleOk} color="primary">
                    Ano
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;