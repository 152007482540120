import React, {useEffect, useState} from 'react';
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import RESTClient from "./RESTClient";
import {useLocation} from "react-router";
import Link from '@material-ui/core/Link';
import {Button, Chip, IconButton} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import Map from "./Map";
import {useRouter} from "./Router";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import ConfirmDialog from "./ConfirmDialog";

const useStyles = makeStyles(theme => ({
    root: {
        //height: "100%",
        padding: "10px"
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    list: {
        width: 250,
    },
    container: {
        maxHeight: "50%"
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

function ActivityDetail() {
    const classes = useStyles();
    const location = useLocation();
    const [activity, setActivity] = useState();
    const router = useRouter();
    const [confirmOpen, setConfirmOpen] = useState(false);

    useEffect(() => {
        const n = location.pathname.lastIndexOf('/');
        const activityId = location.pathname.substring(n + 1);
        RESTClient.get(`/api/activity/${activityId}`,
            response => {
                setActivity(response);
            })
    }, [])

    const handleFilterByTag = (e, tagName) => {
        e.preventDefault();

        const filter = {tags: [tagName]}
        const objJsonB64 = btoa(unescape(encodeURIComponent(JSON.stringify(filter))));
        router.goTo("activityList?filter=" + objJsonB64);
    }

    const renderTags = (tags) => {
        if (tags) {
            return tags.map(t => <Chip key={t.id} onClick={e => handleFilterByTag(e, t.name)} label={t.name}/>);
        }

        return "";
    }

    const handleEdit = e => {
        router.goTo("editActivity?activityId=" + activity.id);
    }

    const handleDelete = () => {
        setConfirmOpen(true);
    }

    const handleDeleteConfirm = e => {
        RESTClient.delete(`/api/activity/${activity.id}`, null, r => router.goTo("activityList"));
    }

    const renderExtLinks = (links) => {
        return links && links
            .split("\n")
            //.map(link => <li key={link}><Link href={link} target="_blank">{link}</Link></li>)
            .map(link => {
                const parsedLink = link.match(/^\[(.+)\](.*)/);
                if (parsedLink) {
                    return <li key={parsedLink[2]}><Link href={parsedLink[2]} target="_blank">{parsedLink[1]}</Link></li>;
                } else {
                    return <li key={link}><Link href={link} target="_blank">{link}</Link></li>;
                }
            });
    }

    return activity ? <Paper className={classes.root}>
            <h1>{activity.name}</h1>
            <img src={activity.img}/>
            <p>{activity.description}</p>
            <ul>
                {renderExtLinks(activity.extLinks)}
            </ul>
            <p>{activity.lat}, {activity.lng} <Link
                href={activity.mapyCzUrl ? activity.mapyCzUrl : `https://mapy.cz/?source=coor&id=${activity.lng}%2C${activity.lat}`}
                target={"_blank"}>Velká mapa</Link></p>
            <p>tags: {renderTags(activity.tags)}</p>
            <p>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDelete}
                    startIcon={<DeleteIcon/>}>
                    Smazat
                </Button>
                <Button
                    variant="contained"
                    color="default"
                    onClick={handleEdit}>
                    Editovat
                </Button>
            </p>
            <ConfirmDialog open={confirmOpen} handleOk={handleDeleteConfirm} handleCancel={() => setConfirmOpen(false)}/>
            <Map lat={activity.lat} lng={activity.lng} height="600px" zoom={15}/>
        </Paper> :
        <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit"/>
        </Backdrop>;
}

export default ActivityDetail;