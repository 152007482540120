import React, {useEffect, useState, useRef} from 'react';
import Map from "./Map";
import RESTClient from "./RESTClient";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

function BigMap() {
    const [list, setList] = useState();
    const classes = useStyles();

    useEffect(() => {
        RESTClient.get("/api/activity/userActivity",
            response => {
                setList(response);
            })
    }, [])


    return list ? <Map activityList={list} /> :
        <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit"/>
        </Backdrop>;
}

export default BigMap;