import React, {useEffect, useState} from 'react';
import {
    makeStyles,
    useTheme,
    createMuiTheme,
    ThemeProvider
} from '@material-ui/core/styles';
import {Button, FormControlLabel, Grid, Paper, TextField} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import RESTClient from "./RESTClient";
import {useHistory} from "react-router";
import {Autocomplete} from "@material-ui/lab";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {green} from '@material-ui/core/colors';
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles(theme => ({
    root: {
        padding: "30px"
    },
    form: {
        marginLeft: "30%"
    },
    form_mobile: {},
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%"
    },
    textFieldSmall: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    gridContainer: {
        width: "50%"
    },
    gridContainer_mobile: {},
    controllButtons: {
        textAlign: "right",
        width: "100%"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

const greenTheme = createMuiTheme({
    palette: {
        primary: green,
    },
});

function AddActivity() {

    const history = useHistory();
    const classes = useStyles();
    const [availTags, setAvailTags] = useState();
    const [activity, setActivity] = useState({
        mapyCzUrl: "",
        name: "",
        description: "",
        lat: "",
        lng: "",
        img: "",
        tags: [],
        publicCheck: false
    })
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isEdit, setEdit] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        RESTClient.get(`/api/tag/all`,
            response => {
                setAvailTags(response);

                const activityId = window.location.href.match(/\?activityId=(.+)$/);
                if (activityId) {
                    setEdit(true);
                    RESTClient.get(`/api/activity/${activityId[1]}`,
                        response => {
                            const activity = {...response, publicCheck: response.visibility === "PUBLIC"};
                            setActivity(activity);
                            setLoading(false);
                        })
                } else {
                    setLoading(false);
                }

                const data = window.location.href.match(/\?data=(.+)$/);
                if (data) {
                    const activity = JSON.parse(decodeURIComponent(escape(window.atob(data[1]))));
                    setActivity({...activity, tags: [], publicCheck: false});
                }
            })
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = {
            ...activity,
            visibility: activity.publicCheck ? "PUBLIC" : "PRIVATE"
        };

        const callback = response => {
            const activityId = response.id;

            history.push("/activityDetail/" + activityId);
        };

        if (isEdit) {
            RESTClient.post("/api/activity", formData, callback);
        } else {
            RESTClient.put("/api/activity", formData, callback);
        }
    };

    const handleChange = e => {
        const newActivity = {...activity};
        newActivity[e.target.id] = e.target.value;

        setActivity(newActivity)
    }

    const handlePublicCheck = e => {
        setActivity({...activity, publicCheck: e.target.checked});
    }

    const parseGps = () => {
        const regex = /^(.+)N, (.+)E$/;
        if (activity.lat) {
            const parsedGps = activity.lat.match(regex);
            if (parsedGps) {
                const newActivity = {...activity};
                newActivity.lat = parsedGps[1];
                newActivity.lng = parsedGps[2];
                setActivity(newActivity);
            }
        }
    }

    return <React.Fragment>
        <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Paper className={classes.root}>
        <form className={isMobile ? classes.form_mobile : classes.form} onSubmit={handleSubmit} noValidate
              autoComplete="off">
            <Grid container spacing={3} className={isMobile ? classes.gridContainer_mobile : classes.gridContainer}>
                <Grid item xs={12}>
                    <TextField className={classes.textField} id="mapyCzUrl" label="Mapy.cz URL" variant="outlined"
                               value={activity.mapyCzUrl} onChange={handleChange}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField className={classes.textField} id="name" label="Název" variant="outlined"
                               value={activity.name} onChange={handleChange}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField className={classes.textField} id="description" label="Popis" variant="outlined"
                               value={activity.description} onChange={handleChange}
                    multiline rows={3} rowsMax={7}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField className={classes.textField} id="extLinks" label="Externí odkazy" variant="outlined"
                               value={activity.extLinks} onChange={handleChange}
                               multiline rows={3} rowsMax={7}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField className={classes.textField} id="lat" label="Latitude" variant="outlined"
                               value={activity.lat} onBlur={parseGps} onChange={handleChange}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField className={classes.textField} id="lng" label="Longitude" variant="outlined"
                               value={activity.lng} onChange={handleChange}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField className={classes.textField} id="img" label="URL obrázku" variant="outlined"
                               value={activity.img} onChange={handleChange}/>
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        id="tags"
                        options={availTags}
                        selectOnFocus
                        clearOnBlur
                        freeSolo
                        value={activity.tags}
                        onChange={(event, newValue) => {
                            const value = [];

                            newValue.forEach(v => {
                                if (v.name) {
                                    value.push(v);
                                } else if (typeof v === "string") {
                                    value.push({name: v});
                                }
                            });

                            setActivity({...activity, tags: value})
                        }}
                        filterOptions={(options, params) => {
                            const filtered = [];

                            options.forEach(o => {
                                const input_norm = params.inputValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                const option_norm = o.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                if (option_norm.startsWith(input_norm)) filtered.push(o);
                            });

                            if (params.inputValue !== '') {
                                filtered.push({name: params.inputValue});
                            }

                            return filtered;
                        }}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.name) {
                                return option.name;
                            }
                            // Regular option
                            return option.name;
                        }}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className={classes.textField}
                                variant="outlined"
                                label="Tagy"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox id="publicCheck" checked={activity.publicCheck} onChange={handlePublicCheck}
                                           color="primary"/>}
                        label="Veřejná aktivita"
                    />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.controllButtons}>
                    <ThemeProvider theme={greenTheme}>
                        <Button color="primary" className={classes.controllButtons} type="submit"
                                variant="contained">{isEdit ? "Editovat" : "Přidat"}</Button>
                    </ThemeProvider>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.controllButtons}>
                    <Button className={classes.controllButtons} onClick={history.goBack}
                            variant="contained">Cancel</Button>
                </Grid>
            </Grid>
        </form>
    </Paper>
    </React.Fragment>;
}

export default AddActivity;