import React, {useEffect, useState} from 'react';
import ActivityCard from "./ActivityCard";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import RESTClient from "./RESTClient";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import {useHistory} from "react-router";
import {Theme, useTheme, makeStyles} from "@material-ui/core/styles";
import {useRouter} from "./Router";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';

const useStyles = makeStyles(theme => ({
    root: {
        //padding: "10px"
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || 'xs'
    );
}

function ActivityList() {
    const classes = useStyles();
    const [list, setList] = useState([]);
    const history = useHistory();
    const router = useRouter();
    const width = useWidth();

    useEffect(() => {
        const filterHash = window.location.href.match(/\?filter=(.+)$/);
        if (filterHash) {
            const filter = JSON.parse(decodeURIComponent(escape(window.atob(filterHash[1]))));
            RESTClient.post("/api/activity/tags",
                filter,
                response => {
                    setList(response);
                })
        } else {
            RESTClient.get("/api/activity/userActivity",
                response => {
                    setList(response);
                })
        }
    }, [])

    const renderList = (list) => {
        let cols;
        switch (width) {
            case "xs":
                cols = 12;
                break;
            case "sm":
                cols = 6;
                break;
            case "md":
                cols = 4;
                break;
            case "lg":
                cols = 3;
                break;
            case "xl":
                cols = 2;
        }

        return list.map(listItem => {
            return <GridListTile key={listItem.id} cols={cols}>
                <ActivityCard {...listItem}/>
            </GridListTile>
        });
    }

    const addActivity = (e) => {
        //history.push("/addActivity");
        router.goTo("addActivity");
    }

    return <React.Fragment>
        <Backdrop className={classes.backdrop} open={list.length === 0}>
            <CircularProgress color="inherit"/>
        </Backdrop>
        <GridList cellHeight={370} className={classes.root} cols={12} /*spacing={2}*/>
            {renderList(list)}
        </GridList>
        <Fab className={classes.fab} color="primary" aria-label="add" onClick={addActivity}>
            <AddIcon/>
        </Fab>
    </React.Fragment>;
}

export default ActivityList;