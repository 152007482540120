import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Grid, Paper, TextField} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(theme => ({
    root: {
        padding: "30px"
    },
    form: {
        marginLeft: "30%"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 500,
    },
    textFieldSmall: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    gridContainer: {
        width: 500
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 500,
        },
    },
};

const genres = [
    "Akční",
    "Animovaný",
    "Dobrodružný",
    "Dokumentární",
    "Drama",
    "Erotický",
    "Experimentální",
    "Fantasy",
    "Film-Noir",
    "Historický",
    "Horor",
    "Hudební",
    "IMAX",
    "Katastrofický",
    "Komedie",
    "Krátkometrážní",
    "Krimi",
    "Loutkový",
    "Muzikál",
    "Mysteriózní",
    "Naučný",
    "Podobenství",
    "Poetický",
    "Pohádka",
    "Pornografický",
    "Povídkový",
    "Psychologický",
    "Publicistický",
    "Reality-TV",
    "Road movie",
    "Rodinný",
    "Romantický",
    "Sci-Fi",
    "Soutěžní",
    "Sportovní",
    "Stand-up",
    "Talk-show",
    "Taneční",
    "Telenovela",
    "Thriller",
    "Válečný",
    "Western",
    "Zábavný",
    "Životopisný"
];

export default function AddMovie() {
    const classes = useStyles();
    const [csfdUrl, setCsfdUrl] = useState("");
    const [name, setName] = useState("");
    const [genre, setGenre] = useState([]);
    const [duration, setDuration] = useState("");
    const [posterUrl, setPosterUrl] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

    };

    return (
        <Paper className={classes.root}>
            <form className={classes.form} onSubmit={handleSubmit} noValidate autoComplete="off">
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={12}>
                        <TextField className={classes.textField} id="csfdUrl" label="Adresa filmu na webu csfd.cz"
                                   variant="outlined" value={csfdUrl} onChange={e => setCsfdUrl(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField className={classes.textField} id="name" label="Název filmu" variant="outlined"
                                   value={name} onChange={e => setName(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        {/*<TextField className={classes.textFieldSmall} id="genre" label="Žánr" variant="outlined" value={genre} onChange={e => setGenre(e.target.value)} />*/}
                        <FormControl className={classes.textField} variant="outlined">
                            <InputLabel id="demo-mutiple-checkbox-label">Žánr</InputLabel>
                            <Select
                                labelId="demo-mutiple-checkbox-label"
                                id="demo-mutiple-checkbox"
                                multiple
                                value={genre}
                                onChange={e => setGenre(e.target.value)}
                                renderValue={selected => selected.join(' / ')}
                                MenuProps={MenuProps}
                            >
                                {genres.map(g => (
                                    <MenuItem key={g} value={g}>
                                        <Checkbox checked={genre.indexOf(g) > -1}/>
                                        <ListItemText primary={g}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField className={classes.textField} id="duration" label="Délka" variant="outlined"
                                   value={duration} onChange={e => setDuration(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField className={classes.textField} id="posterUrl" label="Adresa plakátu"
                                   variant="outlined" value={posterUrl} onChange={e => setPosterUrl(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained">Přidat</Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
}