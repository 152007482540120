class RESTClient {

    static post(method, body, responseProcessor = response => {
    }, errorProcessor = error => {
    }) {
        let headers = {
            "Content-type": "application/json; charset=UTF-8",
            "Accept": "application/json",
            "Authorization": this.getAccessToken()
        };

        fetch(method, {
            method: 'post',
            headers: headers,
            body: JSON.stringify(body)
        })
            .then(function (response) {
                if (response.ok) {
                    if (response.status !== 204) {
                        response.json().then(responseProcessor);
                    } else {
                        responseProcessor(null);
                    }
                } else {
                    response.text().then(txt => JSON.parse(txt)).then(errorProcessor);
                    //response.text().then(txt => console.log(txt));
                }
            })
            .catch(error => console.error('Failed', error));
    }

    static put(method, body, responseProcessor = response => {
    }, errorProcessor = error => {
    }) {
        let headers = {
            "Content-type": "application/json; charset=UTF-8",
            "Accept": "application/json",
            "Authorization": this.getAccessToken()
        };

        fetch(method, {
            method: 'put',
            headers: headers,
            body: JSON.stringify(body)
        })
            .then(function (response) {
                if (response.ok) {
                    if (response.status !== 204) {
                        response.json().then(responseProcessor);
                    } else {
                        responseProcessor(null);
                    }
                } else {
                    response.text().then(txt => JSON.parse(txt)).then(errorProcessor);
                    //response.text().then(txt => console.log(txt));
                }
            })
            .catch(error => console.error('Failed', error));
    }

    static delete(method, body, responseProcessor = response => {
    }, errorProcessor = error => {
    }) {
        let headers = {
            "Content-type": "application/json; charset=UTF-8",
            "Accept": "application/json",
            "Authorization": this.getAccessToken()
        };

        fetch(method, {
            method: 'delete',
            headers: headers,
            body: JSON.stringify(body)
        })
            .then(function (response) {
                if (response.ok) {
                    if (response.status !== 204) {
                        response.json().then(responseProcessor);
                    } else {
                        responseProcessor(null);
                    }
                } else {
                    response.text().then(txt => JSON.parse(txt)).then(errorProcessor);
                    //response.text().then(txt => console.log(txt));
                }
            })
            .catch(error => console.error('Failed', error));
    }

    static get(method, responseProcessor = response => {
    }, errorProcessor = error => {
    }) {
        let headers = {"Authorization": this.getAccessToken()};

        fetch(method, {
            method: 'get',
            headers: headers
        })
            .then(function (response) {
                if (response.ok) {
                    if (response.status !== 204) {
                        response.json().then(responseProcessor);
                    } else {
                        responseProcessor(null);
                    }
                } else {
                    response.text().then(txt => JSON.parse(txt)).then(errorProcessor);
                }
            })
            .catch(error => console.error('Failed', error));
    }

    static getAccessToken() {
        let authResponse = window.FB.getAuthResponse();
        //console.log("AuthResponse: ", authResponse);
        return "Bearer " + authResponse.accessToken;
    }
}

export default RESTClient;
