import React from 'react';
import './LoginDialog.css';
import Button from "@material-ui/core/Button";
import FacebookIcon from '@material-ui/icons/Facebook';
import CircularProgress from "@material-ui/core/CircularProgress";

//var jwtDecode = require('jwt-decode');

function LoginDialog(props) {

    /*constructor(props) {
        super(props);

        this.state = {loginError: null};
    }*/

    const processResponse = (response) => {
        /*console.log('Request succeeded with JSON response', response);*/
        /*var token = response.act;
        localStorage.setItem('act', token);
        var decodedToken = jwtDecode(token);
        localStorage.setItem('userName', decodedToken.sub);

        this.props.logIn();*/
    }

    const processError = (text) => {
        /*console.log('Request failed', text);*/
        /*this.setState({loginError: text.err});*/
    };

    const handleLogin = (e) => {
        e.preventDefault();

        window.FB.login(function (response) {
            if (response.status === "connected") {
               // console.log(response);
                props.loginSuccess({userID: response.authResponse.userID, accessToken: response.authResponse.accessToken});
                /*window.FB.api('/me', function (response) {
                    console.dir(response);
                });*/
            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        });
    };

    return (
        <div className="outer">
            <div className="middle">
                <div className="inner">
                    {props.loading ?
                        <CircularProgress/> :
                        props.fbLogin ?
                            <h2>Musite se registrovat</h2> :
                            <React.Fragment>
                                <h2 style={{textAlign: "center", marginBottom: "1em"}}>Vítejte v mojí appce.</h2>
                                <Button style={{marginBottom: "10px"}} variant="contained" color="primary"
                                        startIcon={<FacebookIcon/>}
                                        onClick={handleLogin}>Přihlásit se</Button>
                            </React.Fragment>
                    }
                </div>
            </div>
        </div>
    );
}

export default LoginDialog;
